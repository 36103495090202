<template>
  <div class="hello">
    <span :class="changeNumber" class="number">{{ currentNumber }}</span>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "number",
  props: {
    msg: String,
  },
  data() {
    return {
      nextNumber: 0,
      changingNumber: true,
      error: "",
      changeNumber: "",
      currentNumber: 0,
      numberQueue: [],
    };
  },
  mounted: function () {
    this.$nextTick(function () {
      window.setInterval(() => {
        this.getName();
      }, 1000);
    });
  },
  watch: {
    nextNumber: function () {
      this.checkQueue();
    },
  },
  methods: {
    getName() {
      axios
        .get(
          "https://spreadsheets.google.com/feeds/list/1Fn916BU3SltWkBUlFdBgaeJLlZwHK_A6wN2RNiYGmDc/1/public/values?alt=json"
        )
        .then((response) => {
          this.nextNumber = response.data.feed.entry[0].gsx$number.$t;
        })
        .catch((error) => console.log(error));
    },
    numberLineUp() {
      this.changingNumber = false;
      if (this.numberQueue.length == 0) {
        this.currentNumber = this.nextNumber;
        this.changeNumber = "flashit";
        setTimeout(() => {
          this.changeNumber = null;
          this.changingNumber = true;
          this.reCheckQueue();
        }, 10000);
      }else{
        this.reCheckQueue();
      }
      
    },
    reCheckQueue (){
      if(this.numberQueue.length > 0){
        this.changingNumber = false;
        this.currentNumber = this.numberQueue[0];
        this.changeNumber = "flashit";
        setTimeout(() => {
            this.changeNumber = null;
            this.changingNumber = true;
            this.numberQueue.shift();
            this.numberLineUp();
          }, 10000);
      } 
    },
    checkQueue() {
      if (this.changingNumber) {
        this.numberLineUp();
      } else {
        this.numberQueue.push(this.nextNumber);
      }
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.number {
  font-size: 80vh;
  color: #fff;
}
.flashit {
  color: #fff;
  -webkit-animation: flash linear 2s infinite;
  animation: flash linear 2s infinite;
}
@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
</style>
